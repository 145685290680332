<template>
	<div class="app flex-row align-items-center">
		<div v-if="vueAppRunningProduction" class="banner-testing-mode"></div>
		<div v-if="vueAppRunningProduction" class="container-testing-mode">
			{{ FormMSG(268, 'Testing') }}
		</div>
		<div class="container">
			<b-row class="login justify-content-center">
				<b-col md="6" sm="6" class="form">
					<b-card no-body class="p-4">
						<b-card-body>
							<img class="navbar-brand-full" src="img/brand/thegreenshot-brand.png" width="120" alt="TheGreenShot" align="right" />
							<br />

							<h2>{{ FormMSG(1, 'Register') }}</h2>
							<p class="text-muted">
								{{ FormMSG(102, 'Free, no credit card required!') }}
							</p>
							<b-input-group class="mb-3">
								<b-input-group-prepend>
									<b-input-group-text>
										<component :is="getLucideIcon('User')" :color="ICONS.PERCENT.color" :size="18" />
									</b-input-group-text>
								</b-input-group-prepend>
								<b-form-input type="text" v-model="userFirstName" class="form-control" :placeholder="FormMSG(6, 'First name')" />
							</b-input-group>
							<b-input-group class="mb-3">
								<b-input-group-prepend>
									<b-input-group-text>
										<component :is="getLucideIcon('User')" :color="ICONS.PERCENT.color" :size="18" />
									</b-input-group-text>
								</b-input-group-prepend>
								<b-form-input type="text" v-model="userName" class="form-control" :placeholder="FormMSG(5, 'Last name')" />
							</b-input-group>
							<b-input-group class="mb-3">
								<b-input-group-prepend>
									<b-input-group-text>
										<component :is="getLucideIcon('MessageSquare')" :color="ICONS.PERCENT.color" :size="18" />
									</b-input-group-text>
								</b-input-group-prepend>
								<b-form-select v-model="userLanguage" :options="optionsForLanguages" id="optionsForLanguages"></b-form-select>
							</b-input-group>

							<b-row class="mb-3">
								<b-col :cols="manageInputEmailCols">
									<b-input-group class="mb-3">
										<b-input-group-prepend>
											<b-input-group-text>
												<component :is="getLucideIcon('AtSign')" :color="ICONS.PERCENT.color" :size="18" />
											</b-input-group-text>
										</b-input-group-prepend>
										<b-form-input
											type="email"
											v-model="email"
											class="form-control"
											:state="stateEmail"
											:placeholder="FormMSG(7, 'Email')"
											:class="{ 'bg-color-grams-hair': emailValidated }"
											:disabled="emailValidated"
											autocomplete="email"
										/>
									</b-input-group>
								</b-col>
								<b-col v-if="!emailValidated" :cols="$screen.width <= 567 ? 12 : 4">
									<b-button variant="primary" block :disabled="disableVerifyEmail || loadingFor.verifyEmail" @click="verifyEmail">
										<span v-if="dataToCheck.emailCodeValidation !== 0 && !loadingFor.verifyEmail">
											{{ FormMSG(45, 'Resend code') }}
										</span>
										<span v-if="!loadingFor.verifyEmail && dataToCheck.emailCodeValidation === 0">
											{{ FormMSG(46, 'Verify email') }}
										</span>
										<span v-if="loadingFor.verifyEmail">
											{{ FormMSG(47, 'Check email') }}
										</span>
									</b-button>
								</b-col>
							</b-row>
							<p v-if="$screen.width <= 567 && showVerificationMail" class="fs-12">
								{{ FormMSG(53, 'Enter email verfication code') }}
							</p>
							<b-row v-if="showVerificationMail" class="mb-3">
								<b-col cols="8">
									<fieldset class="form-group" :horizontal="true" id="fieldset-email-validation" aria-labelledby="aria-phone-validation">
										<div class="form-row">
											<legend
												v-if="$screen.width >= 471"
												tabindex="-1"
												class="col-8 bv-no-focus-ring col-form-label fs-12"
												id="aria-email-validation"
											>
												{{ FormMSG(53, 'Enter email verfication code') }} :
											</legend>
											<div class="col">
												<b-form-input
													v-model="emailCodeValidation"
													:state="stateError.emailCodeValidation"
													placeholder="ex: 123456"
													:class="{ 'bg-color-grams-hair': emailValidated }"
													:disabled="emailValidated"
													@input="handleInputEmailCodeValidation"
												/>
											</div>
										</div>
									</fieldset>
								</b-col>
								<b-col v-if="stateError.emailCodeValidation !== null && stateError.emailCodeValidation === false" cols="4" class="pt-2">
									<X color="#B11548" :size="20" />
								</b-col>
								<b-col v-if="stateError.emailCodeValidation === true" cols="4" :class="$screen.width <= 567 ? 'pt-2' : 'pt-2'">
									<Check color="#28a745" :size="20" />
								</b-col>
							</b-row>
							<div v-show="emailValidated">
								<b-row class="mb-3">
									<b-col :cols="manageInputPhoneCols" :class="$screen.width < 567 ? 'mb-3' : ''">
										<div class="d-flex">
											<div>
												<b-input-group-prepend style="height: 100%">
													<b-input-group-text style="border-radius: 0.25rem 0 0 0.25rem">
														<Phone color="#4d4e4f" :size="16" />
													</b-input-group-text>
												</b-input-group-prepend>
											</div>
											<div>
												<VuePhoneNumberInput
													v-model="phone"
													error-color="#EA4E2C"
													valid-color="#00AF75"
													size="sm"
													:translations="optionsTranslation"
													:disabled="phoneValidated"
													default-country-code="BE"
													@update="handleUpdatePhoneNumber"
												/>
											</div>
										</div>
									</b-col>
									<b-col v-if="!phoneValidated" :cols="$screen.width < 567 ? 12 : 4">
										<b-button variant="primary" block :disabled="disableVerifyPhone || loadingFor.verifyPhone" @click="verifyPhone">
											<span>
												{{ labelPhoneAction }}
											</span>
										</b-button>
									</b-col>
								</b-row>
								<p v-if="$screen.width <= 567 && showVerificationPhone">
									{{ FormMSG(58, 'Enter SMS verfication code') }}
								</p>
								<b-row v-if="showVerificationPhone">
									<b-col cols="8">
										<fieldset class="form-group" :horizontal="true" id="fieldset-phone-validation" aria-labelledby="aria-phone-validation">
											<div class="form-row">
												<legend
													v-if="$screen.width >= 471"
													tabindex="-1"
													class="col-8 bv-no-focus-ring col-form-label"
													id="aria-phone-validation"
												>
													{{ FormMSG(58, 'Enter phone verfication code') }} :
												</legend>
												<div class="col">
													<b-form-input
														v-model="phoneCodeValidation"
														:state="stateError.phoneCodeValidation"
														placeholder="ex: 1234"
														:disabled="phoneValidated"
														@input="handleInputPhoneCodeValidation"
													/>
												</div>
											</div>
										</fieldset>
									</b-col>
									<b-col v-if="stateError.phoneCodeValidation !== null && stateError.phoneCodeValidation === false" cols="4" class="pt-2">
										<X color="#B11548" :size="20" />
									</b-col>
									<b-col v-if="stateError.phoneCodeValidation === true" cols="4" :class="$screen.width <= 567 ? 'pt-2' : 'pt-2'">
										<Check color="#28a745" :size="20" />
									</b-col>
								</b-row>
								<b-row v-show="emailValidated && phoneValidated" class="pb-3">
									<b-col>
										<b-form-checkbox :value="true" :unchecked-value="false" @change="handleChangeCheckTermsOfService">
											{{ FormMSG(81, 'I accept') }}
											<span class="false-link" @click="handleClickTermsOfService">{{
												FormMSG(82, "TheGreenshot's Terms of Service")
											}}</span>
										</b-form-checkbox>
									</b-col>
								</b-row>
							</div>
							<b-button
								v-show="emailValidated && phoneValidated"
								variant="success"
								block
								:disabled="this.disableSave || waitProcessRegistration"
								@click="createAccount"
							>
								<b-spinner v-if="waitProcessRegistration" label="Loading..." small />
								{{ FormMSG(8, 'Create License Account') }}
							</b-button>
							<div  class="pt-3 d-flex justify-content-center">
								<b-button variant="link" class="px-0" @click="$router.back()"> {{ FormMSG(20, 'Back to login page') }} </b-button>
							</div>
						</b-card-body>
					</b-card>
				</b-col>
				<b-modal
					header-class="header-class-modal-doc-package"
					:title="FormMSG(1282, 'Validation code')"
					class="modal-success"
					v-model="showModalInfoCode"
					@ok="showModalInfoCode = false"
					ok-variant="success"
					ok-only
				>
					{{ messageModalInfoCode }}
				</b-modal>
				<b-modal
					header-class="header-class-modal-doc-package"
					:title="FormMSG(12, 'Success!')"
					class="modal-success"
					v-model="showModalAlreadyAccountExist"
					no-close-on-backdrop
					no-close-on-esc
					@ok="handleClickOkAlreadyAccountExist"
					ok-variant="success"
					ok-only
				>
					{{
						FormMSG(
							1295,
							'You will be redirected to login, the system has seen that you already have an account which is not yet linked to a SOLO project. You can login with your current email and password'
						)
					}}
				</b-modal>
				<b-modal
					header-class="header-class-modal-doc-package"
					:title="FormMSG(1290, 'Error')"
					class="modal-danger"
					v-model="showModalError"
					@ok="showModalError = false"
					ok-variant="danger"
					ok-only
				>
					{{ FormMSG(1294, 'You can no longer create an account.') }}
				</b-modal>
				<b-modal
					header-class="header-class-modal-doc-package"
					:title="FormMSG(12, 'Success!')"
					class="modal-success"
					v-model="successModal"
					@ok="this.callEnd"
					ok-variant="success"
					ok-only
				>
					An e-mail containing a validation code has just been sent to {{ dataToCheck.email }}
				</b-modal>
				<b-modal
					header-class="header-class-modal-doc-package"
					:title="FormMSG(428, 'Error!')"
					class="modal-danger"
					v-model="alreadyExistModal"
					@ok="this.callEnd"
					ok-variant="danger"
					ok-only
				>
					<h2>
						{{ FormMSG(427, `You already registered a single user free project!`) }}
					</h2>
				</b-modal>
				<b-modal
					header-class="header-class-modal-doc-package"
					:title="FormMSG(11, 'Field validation error')"
					class="modal-danger"
					v-model="errorModal"
					@ok="errorModal = false"
					ok-variant="danger"
					ok-only
				>
					{{ FormMSG(10, 'All fields must be filled with valid data!') }}
				</b-modal>
				<b-modal
					header-class="header-class-modal-doc-package"
					title="Email address invalid"
					class="modal-danger"
					v-model="errorEmailModal"
					@ok="errorEmailModal = false"
					ok-variant="danger"
					ok-only
				>
					{{ FormMSG(13, 'Check the email address provided, it is invalid!') }}
				</b-modal>
			</b-row>
		</div>
	</div>
</template>

<script>
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import _ from 'lodash';
import { Check, X, Phone } from 'lucide-vue';

import languageMessages from '@/mixins/languageMessages';
import { emailValidationCode, phoneValidationCode, signinService, checkLicenseEmailValidationCode, checkLicensePhoneValidationCode } from '@/cruds/registration.crud';
import { addLicenseRequest } from '@/cruds/licenseRequest.crud';
import globalMixin from '@/mixins/global.mixin';

import { NANP_COUNTRY_CODES } from '@/shared/constants';

/**
 * commented by: Lanja
 * reason: not in used, must be deleted in the future.
 */
// const mutation_addLicenseRequest = gql`
// 	mutation (
// 		$email: String
// 		$phone: String
// 		$licenseOwner: String
// 		$projectTitle: String
// 		$language: Int
// 		$timeZone: String
// 		$userName: String
// 		$userFirstName: String
// 		$password: String
// 		$userTypeForFilm: Int
// 		$nextProductionDate: String
// 		$budgetBracket: String
// 	) {
// 		AddLicenseRequest(
// 			NewLicenseRequest: {
// 				email: $email
// 				licenseOwner: $licenseOwner
// 				projectTitle: $projectTitle
// 				language: $language
// 				timeZone: $timeZone
// 				userName: $userName
// 				userFirstName: $userFirstName
// 				password: $password
// 				userTypeForFilm: $userTypeForFilm
// 				nextProductionDate: $nextProductionDate
// 				budgetBracket: $budgetBracket
// 				phone: $phone
// 			}
// 		) {
// 			email
// 			licenseOwner
// 			projectTitle
// 			userName
// 			userFirstName
// 			xid
// 		}
// 	}
// `;

export default {
	name: 'NewRegister',
	mixins: [languageMessages, globalMixin],
	components: {
		VuePhoneNumberInput,
		Check,
		X,
		Phone
	},
	data() {
		return {
			dataToCheck: {
				email: '',
				phone: '',
				emailCodeValidation: 0,
				phoneCodeValidation: 0,
				termsOfService: false,
			},
			loadingFor: {
				verifyEmail: false,
				verifyPhone: false
			},
			stateError: {
				phoneCodeValidation: null,
				emailCodeValidation: null
			},
			emailCodeValidation: '',
			phoneCodeValidation: '',
			successModal: false,
			errorModal: false,
			alreadyExistModal: false,
			errorEmailModal: false,
			licenseOwner: '',
			projectTitle: '',
			userName: '',
			userFirstName: '',
			userLanguage: 0,
			email: '',
			password: '',
			repeat_password: '',
			userTypeForFilm: 5,
			nextProductionDate: '2021-09-01T00:00:00+00:00',
			budgetBracket: '',
			phone: '',
			phoneE164: '',
			disableVerifyPhone: true,
			showModalInfoCode: false,
			messageModalInfoCode: '',

			emailValidated: false,
			phoneValidated: false,
			waitProcessRegistration: false,
			emailValidationProcess: 0,
			showModalError: false,
			stateEmail: null,
			showModalAlreadyAccountExist: false,

			isNANPCountry: false
		};
	},
	computed: {
		manageInputEmailCols() {
			if (!this.emailValidated) {
				if (this.$screen.width <= 567) {
					return 12;
				} else {
					return 8;
				}
			} else {
				return 12;
			}
		},
		manageInputPhoneCols() {
			if (!this.phoneValidated) {
				if (this.$screen.width <= 567) {
					return 12;
				} else {
					return 8;
				}
			} else {
				return 12;
			}
		},
		labelPhoneAction() {
			if (this.isNANPCountry) {
				return this.FormMSG(159, 'Validated');
			}
			if (this.dataToCheck.phoneCodeValidation !== 0 && !this.loadingFor.verifyPhone) {
				return this.FormMSG(48, 'Resend code');
			}
			if (!this.loadingFor.verifyPhone && this.dataToCheck.phoneCodeValidation === 0) {
				return this.FormMSG(49, 'Verify phone');
			}
			if (this.loadingFor.verifyPhone) {
				return this.FormMSG(50, 'Check phone');
			}
		},
		isEqualEmailCodeValidation() {
			return parseInt(this.dataToCheck.emailCodeValidation) === parseInt(this.emailCodeValidation);
		},
		isEqualPhoneCodeValidation() {
			return parseInt(this.dataToCheck.phoneCodeValidation) === parseInt(this.phoneCodeValidation);
		},
		disableVerifyEmail() {
			const emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
			if (!emailfilter.test(this.email)) {
				return true;
			}
			return false;
		},
		showVerificationMail() {
			if (this.dataToCheck.emailCodeValidation !== 0) {
				return true;
			}
			return false;
		},
		showVerificationPhone() {
			if (this.dataToCheck.phoneCodeValidation !== 0) {
				return true;
			}
			return false;
		},
		optionsTranslation() {
			return {
				countrySelectorLabel: this.FormMSG(54, 'Country code'),
				countrySelectorError: this.FormMSG(55, 'Choose a country'),
				phoneNumberLabel: this.FormMSG(56, 'Phone number'),
				example: 'Ex : '
			};
		},
		optionsForLanguages() {
			/*
      var mnu = await getMenuArrayFromDb(1);
      console.log("in optionsForLanguages mnu:",mnu);
      return mnu; */
			// create database only in validated languages
			return this.FormMenu(1115);
		},
		optionsForUserTypeForFilm() {
			return this.FormMenu(1114);
		},
		disableSave() {
			/*if(this.showQuestions()){
        if (
          this.licenseOwner.length == 0 ||
          this.projectTitle.length == 0) {
          return true;
        }
      }*/
			if (
				this.userName.length !== 0 &&
				this.userFirstName.length !== 0 &&
				this.email.length !== 0 &&
				this.phoneE164.length !== 0 &&
				this.stateError.phoneCodeValidation &&
				this.stateError.emailCodeValidation &&
				this.dataToCheck.termsOfService
			) {
				return false;
			}
			// check if email is valid
			/*var emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
      if (!emailfilter.test(this.email)) {
        return true;
      }*/
			// end check if email is valid
			return true;
		},
		vueAppRunningProduction() {
			return process.env.VUE_APP_RUNNING_MODE !== 'PROD';
		}
	},
	methods: {
		async handleInputPhoneCodeValidation() {
			let verif = await this.verifyMatchingCode()
			if (!verif) {
				this.stateError.phoneCodeValidation = false;
				this.phoneValidated = false;
			} else {
				this.stateError.phoneCodeValidation = true;
				this.phoneValidated = true;
			}
		},
		async verifyMatchingCode(){
			if (this.dataToCheck.licenseIdPhone) {
				try{
					const isValidFromServer = await checkLicensePhoneValidationCode(
						this.dataToCheck.licenseIdPhone,
						this.phoneCodeValidation
					);
					return isValidFromServer.CheckLicensePhoneValidationCode;
				} catch(error) {
					return false;
				}
			}
		},
		handleClickOkAlreadyAccountExist() {
			this.showModalAlreadyAccountExist = false;
			this.$cookies.set('emailRegister', this.email);
			this.$router.push('/pages/login');
		},
		handleClickTermsOfService() {
			this.dataToCheck.termsOfService = true;
			if (parseInt(this.userLanguage) === 1) {
				window.open('https://www.thegreenshot.green/fr/termes-et-conditions', '_blank');
			} else {
				window.open('https://www.thegreenshot.green/terms-and-conditions', '_blank');
			}
		},
		handleChangeCheckTermsOfService(payload) {
			this.dataToCheck.termsOfService = payload;
		},
		async handleInputEmailCodeValidation() {
			if (this.dataToCheck.licenseId && this.emailCodeValidation) {
				const isValidFromServer = await checkLicenseEmailValidationCode(this.dataToCheck.licenseId, this.emailCodeValidation);
				const codeVerified = isValidFromServer ? isValidFromServer.CheckLicenseEmailValidationCode : null;
				if (codeVerified) {
					this.stateError.emailCodeValidation = true;
					this.emailValidated = true;
				}else {
					this.stateError.emailCodeValidation = false;
					this.emailValidated = false;
				}
			}
		},
		verifyValidationEmailPhone() {
			if (!this.isNANPCountry) {
				return (
					this.dataToCheck.email === this.email &&
					this.dataToCheck.phone === this.phoneE164 &&
					this.stateError.phoneCodeValidation &&
					this.stateError.emailCodeValidation
				);
			} else {
				return this.dataToCheck.email === this.email && this.stateError.emailCodeValidation  && this.stateError.phoneCodeValidation;
			}
		},
		async verifyEmail() {
			this.loadingFor.verifyEmail = true;
			const email = this.email;
			const result = await emailValidationCode({
				userName: this.userName,
				userFirstName: this.userFirstName,
				email: this.email,
				language: this.userLanguage
			});
			this.dataToCheck.emailCodeValidation = result.emailValidationCode;
			// this.dataToCheck.emailCodeValidation = '1234'
			this.dataToCheck.licenseId = result.id
			this.dataToCheck.email = email;

			this.showModalInfoCode = true;
			this.messageModalInfoCode = this.FormMSG(60, 'An e-mail containing a validation code has just been sent to') + ' ' + email;
			this.stateError.emailCodeValidation = null;
			this.emailValidationProcess = result.emailValidationProcess;
			this.stateEmail = null;
			this.loadingFor.verifyEmail = false;
		},
		async verifyPhone() {
			if (!this.isNANPCountry) {
				this.loadingFor.verifyPhone = true;
				const phone = this.phoneE164;
				const result = await phoneValidationCode({
					userName: this.userName,
					userFirstName: this.userFirstName,
					email: this.email,
					phone: this.phoneE164,
					language: this.userLanguage
				});
				this.dataToCheck.licenseIdPhone = result.id
				this.dataToCheck.phoneCodeValidation = result.phoneValidationCode;
				// this.dataToCheck.phoneCodeValidation = '1234'
				this.dataToCheck.phone = phone;

				this.showModalInfoCode = true;
				this.messageModalInfoCode = this.FormMSG(61, 'An SMS containing a validation code has just been sent to') + ' ' + phone;

				this.phoneCodeValidation = '';
				this.stateError.phoneCodeValidation = null;
				this.loadingFor.verifyPhone = false;
			} else {
				this.stateError.phoneCodeValidation = true;
			}
		},
		handleUpdatePhoneNumber(payload) {
			if (payload.e164) {
				this.phoneE164 = payload.e164;
			} else {
				this.phoneE164 = '';
			}
			if (_.has(payload, 'isValid')) {
				this.disableVerifyPhone = !payload.isValid;
			}
			if (NANP_COUNTRY_CODES.includes(payload.countryCode)) {
				this.isNANPCountry = true;
			}
		},
		showQuestions() {
			return this.userTypeForFilm == 1;
		},
		updateNextProductionDate: function ($event) {
			if (this.nextProductionDate.startsWith('T')) {
				this.nextProductionDate = $event + this.nextProductionDate;
			} else {
				this.nextProductionDate = $event + this.nextProductionDate.substring(10);
			}
		},
		callEnd() {
			console.log('in coallEnd');
			this.successModal = false;
			this.alreadyExistModal = false;
			this.$router.push('/pages/login').catch(() => {});
		},
		createAccount() {
			this.waitProcessRegistration = true;
			if (this.verifyValidationEmailPhone()) {
				if (this.emailValidationProcess !== 1) {
					if (this.userTypeForFilm === 5) {
						// technician project initialise the not displayed values
						this.licenseOwner = this.userName + ' ' + this.userFirstName;
						this.projectTitle = this.FormMSG(230, 'My Project');
						this.budgetBracket = this.FormMSG(231, 'FREE');
					}
					var tzName = moment.tz.guess();
					// console.log("tzName:",tzName)
					var timezone = moment.tz(tzName).zoneAbbr();
					//console.log("timezone:",timezone)

					// var req = mutation_addLicenseRequest;
					var curLanguage = this.userLanguage;

					addLicenseRequest({
						email: this.email,
						licenseOwner: this.licenseOwner,
						projectTitle: this.projectTitle,
						language: curLanguage,
						timeZone: timezone,
						userName: this.userName,
						userFirstName: this.userFirstName,
						password: this.password,
						userTypeForFilm: this.userTypeForFilm,
						nextProductionDate: this.nextProductionDate,
						budgetBracket: this.budgetBracket,
						phone: this.phoneE164
					})
						.then(async (result) => {
							console.log(result);
							const license = result;
							//this.successModal = true;
							this.waitProcessRegistration = false;
							if (this.emailValidationProcess === 2) {
								try {
									await signinService(this.email, '', license.xid, '', true);
									this.showModalAlreadyAccountExist = true;
								} catch (error) {
									console.log(error);
								}
							} else {
								this.$router.push(`/pages/login?NL=${license.xid}`);
							}
						})
						.catch((error) => {
							this.waitProcessRegistration = false;
							const _t = this.FormMSG;
							if (error.networkError) {
								alert(_t(23231, 'Your computer does not seem to be connected to the internet.'));
							} else if (error.graphQLErrors.length > 0) {
								var status = error.graphQLErrors[0].status;
								var message = error.graphQLErrors[0].message;
								if (status == 427) {
									this.alreadyExistModal = true;
								} else {
									alert(_t(23232, 'There was an error. Please try again later.'));
									//console.log("error " + status + ": " + message)
									//                 console.log("erreur:",error)
								}
							} else {
								alert(_t(23233, 'There was an error. Check values and retry.'));
								console.log('unknown error: ' + JSON.stringify(error));
								console.log('erreur:', error);
							}
						});

					// this.$apollo
					// 	.mutate({
					// 		mutation: req,
					// 		variables: {
					// 			email: this.email,
					// 			licenseOwner: this.licenseOwner,
					// 			projectTitle: this.projectTitle,
					// 			language: curLanguage,
					// 			timeZone: timezone,
					// 			userName: this.userName,
					// 			userFirstName: this.userFirstName,
					// 			password: this.password,
					// 			userTypeForFilm: this.userTypeForFilm,
					// 			nextProductionDate: this.nextProductionDate,
					// 			budgetBracket: this.budgetBracket,
					// 			phone: this.phoneE164
					// 		}
					// 	})
					// 	.then(async (result) => {
					// 		console.log(result.data.AddLicenseRequest);
					// 		const license = result.data.AddLicenseRequest;
					// 		//this.successModal = true;
					// 		this.waitProcessRegistration = false;
					// 		if (this.emailValidationProcess === 2) {
					// 			try {
					// 				await signinService(this.email, '', license.xid, '', true);
					// 				this.showModalAlreadyAccountExist = true;
					// 			} catch (error) {
					// 				console.log(error);
					// 			}
					// 		} else {
					// 			this.$router.push(`/pages/login?NL=${license.xid}`);
					// 		}
					// 	})
					// 	.catch((error) => {
					// 		this.waitProcessRegistration = false;
					// 		const _t = this.FormMSG;
					// 		if (error.networkError) {
					// 			alert(_t(23231, 'Your computer does not seem to be connected to the internet.'));
					// 		} else if (error.graphQLErrors.length > 0) {
					// 			var status = error.graphQLErrors[0].status;
					// 			var message = error.graphQLErrors[0].message;
					// 			if (status == 427) {
					// 				this.alreadyExistModal = true;
					// 			} else {
					// 				alert(_t(23232, 'There was an error. Please try again later.'));
					// 				//console.log("error " + status + ": " + message)
					// 				//                 console.log("erreur:",error)
					// 			}
					// 		} else {
					// 			alert(_t(23233, 'There was an error. Check values and retry.'));
					// 			console.log('unknown error: ' + JSON.stringify(error));
					// 			console.log('erreur:', error);
					// 		}
					// 	});
				} else {
					this.showModalError = true;
					this.resetData();
				}
			} else {
				this.waitProcessRegistration = false;
			}
		},
		registerNow() {
			this.$router.push('/pages/register');
		},
		resetData() {
			this.dataToCheck = {
				email: '',
				phone: '',
				emailCodeValidation: 0,
				phoneCodeValidation: 0,
				termsOfService: false
			};
			this.loadingFor = {
				verifyEmail: false,
				verifyPhone: false
			};
			this.stateError = {
				phoneCodeValidation: null,
				emailCodeValidation: null
			};
			this.emailCodeValidation = '';
			this.phoneCodeValidation = '';
			this.successModal = false;
			this.errorModal = false;
			this.alreadyExistModal = false;
			this.errorEmailModal = false;
			this.licenseOwner = '';
			this.projectTitle = '';
			this.userName = '';
			this.userFirstName = '';
			this.userLanguage = 0;
			this.email = '';
			this.password = '';
			this.repeat_password = '';
			this.userTypeForFilm = 5;
			this.nextProductionDate = '2021-09-01T00:00:00+00:00';
			this.budgetBracket = '';
			this.phone = '';
			this.phoneE164 = '';
			this.disableVerifyPhone = true;
			this.showModalInfoCode = false;
			this.messageModalInfoCode = '';
			this.emailValidated = false;
			this.phoneValidated = false;
			this.waitProcessRegistration = false;
			this.emailValidationProcess = 0;
			this.stateEmail = null;
		}
	}
};
</script>

<style>
.banner-testing-mode {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: 3px;
	background-color: #f66;
}
.container-testing-mode {
	position: absolute;
	top: 3px;
	right: 0;
	padding: 15px;
	background-color: #f66;
	color: white;
	font-size: 13px;
	font-weight: bolder;
	border-radius: 0px 0px 0px 15px;
}
</style>
